const { GLOBAL_LOCALES } = require('@rsc/gatsby-localization-helpers');

const regions = {
  Americas: 'Americas',
  Europe: 'Europe',
  Oceania: 'Oceania',
  Nordic: 'Nordic',
};

const languages = [
  {
    ...GLOBAL_LOCALES.English,
    countryName: 'United States',
    region: regions.Americas,
    flag: '/flag_us.svg',
    isDefault: true,
  },
  {
    ...GLOBAL_LOCALES.Australia,
    countryName: 'Australia',
    region: regions.Oceania,
    flag: '/flag_au.svg',
  },
  {
    ...GLOBAL_LOCALES.EnglishUK,
    countryName: 'United Kingdom',
    region: regions.Europe,
    flag: '/flag_gb.svg',
  },
  {
    ...GLOBAL_LOCALES.French,
    countryName: 'France (Français)',
    region: regions.Europe,
    flag: '/flag_fr.svg',
  },
];

function getDefaultLanguage() {
  return languages.find(lg => lg.isDefault);
}

module.exports = {
  languages,
  regions,
  defaultLanguage: getDefaultLanguage(),
};
