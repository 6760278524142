const notFoundPage = {
  key: 'notFoundPage',
  name: 'Not Found Page',

  pageTitle: `We're sorry, the page you're looking for cannot be found`,

  header: 'PAGE NOT FOUND',
  tryToReachSubtitle: 'You are trying to reach the page {url}',
  body: `It looks like that page doesn't exist. You can go back to our
            {homePageLink} to start your search again, or you can
            use the navigation at the top of this page to help you find what
            you're looking for.`,
  searchFieldLabel: 'Search the SportDOG website for what you are looking for:',
  searchPlaceholder: 'Enter your search',
  searchValidationMessage: 'Please fill out this field',
  searchButton: 'Search >',
  homePageInlineLink: 'home page',
};

exports.notFoundPage = notFoundPage;
