import * as React from 'react';
import { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './src/components/theme';

interface RootWrapperProps {
  element: ReactElement;
}
export function RootWrapper({ element }: Readonly<RootWrapperProps>) {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
}
