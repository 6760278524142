const productDetailPage = {
  key: 'productDetailPage',
  name: 'Product Detail Page',

  downloadSectionHeading: 'SUPPORT',
  satisfactionGuaranteedPromoCalloutTopText: 'Satisfaction Guaranteed',
  hassleFreeReturnsPromoCalloutBottomText: '100% Hassle-Free Returns',
  freeShippingPromoCalloutTopText: 'Free Shipping',
  freeShippingConditionLabel: 'on orders over $89',
  relatedProductsHeader: 'Related Products',
  reviewsHeader: 'Reviews',
  questionsAndAnswersHeader: 'Questions & Answers',
};

module.exports = {
  productDetailPage,
};
