const fallbackSportdogCommon = {
  key: 'fallbackSportdogCommon',
  name: 'Fallback Sportdog Common',

  loading: 'Loading...',
};

module.exports = {
  fallbackSportdogCommon,
};
