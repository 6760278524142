const breadcrumbsCommon = {
  key: 'breadcrumbsCommon',
  name: 'Breadcrumbs Common',

  home: 'Home',
  trainingTips: 'Training Tips',
  authors: 'Authors',
  partsAndAccessories: 'Parts & Accessories',
  pressRoom: 'Press Room',
  pressReleases: '{year} Press Releases',
  pressRelease: 'Press Release',
  searchUnavailable: 'Search unavailable',
  whereToBuy: 'Where To Buy',
  account: 'Account',
  accountAddresses: 'Addresses',
  signIn: 'Sign In',
  createAccount: 'Create Account',
  reactivate: 'Reactivate',
  resetPassword: 'Reset Password',
  accountSettings: 'Settings',
  accountVerification: 'Account Verification',
  productRegister: 'Product Registration',
  orders: 'Orders',
};

exports.breadcrumbsCommon = breadcrumbsCommon;
