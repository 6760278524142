const productRegisterPage = {
  key: 'productRegisterPage',
  name: 'Product Register Page',

  pageTitle: 'Product Registration',
  pageDescription:
    'Registering your product will allow us to provide better service in the case of warranty assistance. For more information, contact Customer Care.',

  header: 'Register Your Product',
  body: `Please use the form below to provide your contact and product
          information. Registering your product will allow us to provide better
          service in the case of warranty assistance. For more information,
          contact Customer Care.`,

  formFirstNameLabel: 'First Name',
  formLastNameLabel: 'Last Name',
  formPhoneLabel: 'Phone',
  formAddressLine1Label: 'Address Line 1',
  formAddressLine2Label: 'Address Line 2',
  formCityLabel: 'City',
  formStateLabel: 'State',
  formZipCodeLabel: 'ZIP Code',
  formEmailLabel: 'Email',
  formSearchProductLabel: 'Search for your product by name or SKU',
  formProductLabel: 'Product name or SKU',
  formDatePurchaseLabel: 'Date of Purchase',
  formPlacePurchaseLabel: 'Place of Purchase',

  placePurchasePlaceholder: 'Choose Place of Purchase',
  placePurchaseAmazonUsa: 'Amazon - USA',
  placePurchaseAmazonCanada: 'Amazon - Canada',
  placePurchaseAmazonMexico: 'Amazon - Mexico',
  placePurchaseBassProShop: 'Bass Pro Shop',
  placePurchaseChewy: 'Chewy',
  placePurchaseMenards: 'Menards',
  placePurchaseOther: 'Other',
  placePurchasePetCo: 'PetCo',
  placePurchasePetSmart: 'PetSmart',
  placePurchaseTractorSupply: 'Tractor Supply',
  placePurchaseWalMart: 'Wal-Mart',

  formDateMinValidationMessage: 'Date of Purchase is too far in the past.',
  formDateMaxValidationMessage: 'Date of Purchase cannot be in future.',

  huntingTrainingInterestsHeader: `What are your hunting and training interests (please check all
              that apply)?`,
  obedienceBasicTrainingLabel: 'Obedience/Basic Training',
  uplandHuntingLabel: 'Upland Hunting',
  waterfowlHuntingLabel: 'Waterfowl Hunting',
  houndHuntingLabel: 'Hound Hunting',
  shedHuntingLabel: 'Shed Hunting',

  informAboutNewCheckbox: `I would like to be informed about new products and happenings
                  from SportDOG® Brand.`,

  consentRequestHeader: 'Consent Request:',
  consentRequestBody: `By submitting this form, you
            consent to our use of the personal data entered above to communicate
            with you in regard to your registered sku. For more information
            about our data use policies, see our {privacyPolicyLink}.`,

  submitButton: 'REGISTER NOW >',

  fallbackBody: `We are currently working on improving our product registration. In the meantime, if you need support with your product, please {contactCustomerCareLink}.`,
  contactCustomerCareLink: 'contact Customer Care',
  privacyPolicyInlineLink: 'privacy policy',
};

const productRegisterSuccessPage = {
  key: 'productRegisterSuccessPage',
  name: 'Product Register Success Page',

  pageTitle: 'Successful',
  body: 'Your product information has been registered,',
  bodyThanks: 'Thank You.',
};

const productRegisterFailPage = {
  key: 'productRegisterFailPage',
  name: 'Product Register Fail Page',

  pageTitle: 'Failed',
  body: `We have encountered a problem trying to register your product. Please
          try again later.`,
  bodyThanks: `Thank You.`,
};

module.exports = {
  productRegisterPage,
  productRegisterSuccessPage,
  productRegisterFailPage,
};
