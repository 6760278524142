const prostaffPage = {
  key: 'prostaffPage',
  name: 'Prostaff Page',

  articlesBy: 'Articles by {title}',
  allArticlesBy: 'View All Articles by {title}',
  otherProstaff: 'Other Senior Prostaff',
  websiteLinksHeader: 'Websites',
};

exports.prostaffPage = prostaffPage;
