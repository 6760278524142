const accountCommon = {
  key: 'accountCommon',
  name: 'Account Common',

  pageTitle: 'Account',
  pageDescription:
    'Hunting dog gear designed by sportsmen. Sporting dog training, tracking, and hunting information to get the most out of your next hunt.',

  addressesPageTitle: 'Addresses',
  settingsPageTitle: 'Settings',
};

const createAccountPage = {
  key: 'createAccountPage',
  name: 'Create Account Page',

  pageTitle: 'Create Account',
  header: 'Create Account',
  oneAccountNote:
    'Your account can be used to sign in with any of our family of brands.',
};

const signInPage = {
  key: 'signInPage',
  name: 'Sign In Page',

  pageTitle: 'Login',
  header: 'Sign In',

  createAccountSectionHeader: 'Create Account',
  createAccountButton: 'Create Account',
  checkoutFasterLabel: 'Checkout faster',
  saveShippingAddressesLabel: 'Save multiple shipping addresses',
  accessOrderHistoryLabel: 'Access your order history',
  trackOrdersLabel: 'Track new orders',
};

const resetPasswordPage = {
  key: 'resetPasswordPage',
  name: 'Reset Password Page',

  pageTitle: 'Reset Password',
  header: 'Reset Password',
  reactivateAccountHeader: 'Reactivate Account',
  oneAccountNote: 'Your password will be reset across our family of brands.',
};

const verifyAccountPage = {
  key: 'verifyAccountPage',
  name: 'verify Account Page',

  pageTitle: 'Verify Account',
  header: 'Account Verification',

  successMessage:
    'You have successfully requested a new verification link. Please check your inbox.',
  errorMessage:
    'There was a problem requesting a new verification code. Please try again later or contact customer support.',
  invalidRequestMessage: 'Invalid request.',
};

module.exports = {
  accountCommon,
  createAccountPage,
  signInPage,
  resetPasswordPage,
  verifyAccountPage,
};
