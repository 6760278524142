const { uiComponentsLocalizationData } = require('@rsc/static-translations');
const {
  useInternationalHelper,
  useInternationalContext,
} = require('@rsc/gatsby-localization-helpers');

const { prostaffPage } = require('./prostaffPage');
const {
  donationRequestsCommon,
  donationRequestsFailPage,
  donationRequestsSuccessPage,
} = require('./donation-requests');
const {
  accountCommon,
  createAccountPage,
  signInPage,
  resetPasswordPage,
  verifyAccountPage,
} = require('./account');
const {
  emailUpdatesPage,
  emailUpdatesSuccessPage,
} = require('./email-updates');
const {
  productRegisterPage,
  productRegisterSuccessPage,
  productRegisterFailPage,
} = require('./productRegisterPage');
const { notFoundPage } = require('./notFoundPage');
const { internationalPage } = require('./internationalPage');
const { logoutPage } = require('./logoutPage');
const { searchPage } = require('./searchPage');
const { whereToBuyPage } = require('./whereToBuyPage');
const { breadcrumbsCommon } = require('./breadcrumbsCommon');
const { articleListPage } = require('./articleListPage');
const { collectionsPage } = require('./collectionsPage');
const { pressRoomDetailPage } = require('./pressRoomDetailPage');
const { pressRoomListPage } = require('./pressRoomListPage');
const { productDetailPage } = require('./productDetailPage');
const { prostaffCategoryPage } = require('./prostaffCategoryPage');
const {
  validationMessagesSportdogCommon,
} = require('./validationMessagesSportdogCommon');
const { layoutCommon } = require('./layoutCommon');
const { ordersPage } = require('./ordersPage');
const { gearTagLineComponent } = require('./gearTagLineComponent');
const { fallbackSportdogCommon } = require('./fallbackSportdogCommon');

const sportdogLocalizationStructure = {
  ...uiComponentsLocalizationData,
  prostaffPage,
  donationRequestsCommon,
  donationRequestsFailPage,
  donationRequestsSuccessPage,
  accountCommon,
  createAccountPage,
  signInPage,
  resetPasswordPage,
  verifyAccountPage,
  emailUpdatesPage,
  emailUpdatesSuccessPage,
  productRegisterPage,
  productRegisterSuccessPage,
  productRegisterFailPage,
  notFoundPage,
  internationalPage,
  logoutPage,
  searchPage,
  whereToBuyPage,
  breadcrumbsCommon,
  articleListPage,
  collectionsPage,
  pressRoomDetailPage,
  pressRoomListPage,
  productDetailPage,
  prostaffCategoryPage,
  validationMessagesSportdogCommon,
  layoutCommon,
  ordersPage,
  gearTagLineComponent,
  fallbackSportdogCommon,
};

// Use this hook to initialize InternationalContext for Sportdog site
const useSportdogStaticContentLocalizer = locale => {
  let translationCache;
  try {
    translationCache =
      // eslint-disable-next-line global-require
      require('../../../localization.json');
  } catch {
    console.log('Sportdog localization file not found');
    translationCache = { categories: [] }; // Fallback for missing file
  }
  return useInternationalHelper(
    locale,
    translationCache,
    sportdogLocalizationStructure
  );
};

const useSportdogInternationalContext = () =>
  useInternationalContext(sportdogLocalizationStructure);

module.exports = {
  sportdogLocalizationStructure,
  useSportdogStaticContentLocalizer,
  useSportdogInternationalContext,
};
