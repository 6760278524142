const layoutCommon = {
  key: 'layoutCommon',
  name: 'Layout Common',

  cantPurchaseWarningMessage: `Purchases cannot be completed in the store right now. Please refresh
            or try again later.`,
  footerCustomerCareHeader: 'Customer Care',
  footerDownloadsHeader: 'Downloads',
  footerFindUsOnHeader: 'Find us on',
  footerEmailUpdatesHeader: 'Get email updates',

  sendEmailLink: 'Send an email',
  findRetailerLink: 'Find A Retailer',
  supportSiteLink: 'Support Site',
  changeCountryLink: '({currencyCode}) - CHANGE COUNTRY',
  getCatalogLink: 'Get the Catalog',
  basicTrainingManualLink: 'Basic Training Manual',
  emailAddressLabel: 'Email Address',
  emailLabel: 'Email',
  emailAddressSubmitButton: 'Sign Up',

  petsafeBrandsLink: 'PetSafe Brands',
  allRightsReservedLabel: 'All Rights Reserved.',
  privacyPolicyLink: 'Privacy Statement',
  termsAndConditionsLink: 'Terms & Conditions',
  notSellOrSharePersonalInfoLink:
    'Do Not Sell or Share My Personal Information',
  cookieSettingsButton: 'Cookie Settings',
  cookieSettingsButtonAriaLabel: 'Open cookie settings modal',
};

exports.layoutCommon = layoutCommon;
