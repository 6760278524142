const getResizedProductImage = image => {
  if (image?.includes('.jpg')) {
    return image.replace('.jpg', '_300x.jpg');
  }
  if (image?.includes('.png')) {
    return image.replace('.png', '_300x.png');
  }
  return image;
};

export default getResizedProductImage;
