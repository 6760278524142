import styles from './utils/styles';

const colors = {
  accordion: {
    background: styles.colors.gray[800],
    backgroundHover: styles.colors.gray[600],
  },
  accountMenuBackground: styles.colors.gray[900],
  accountMenuItemBorder: styles.colors.gray[600],
  accountMenuItemCurrentBackground: styles.colors.gray[800],

  background: styles.colors.gray[900],
  badge: styles.colors.orange[500],
  badgeText: styles.colors.black,
  border: styles.colors.gray[600],
  brandPrimary: styles.colors.orange[500],
  brandSecondary: styles.colors.gray[700],
  breadcrumbLinkColor: styles.colors.gray[300],
  carouselBulletHover: styles.colors.orange[500],
  defaultFont: styles.colors.white,
  headerBackground: 'transparent',
  hero: {
    mobileHeadlineColor: '#FFFFFF',
    primary: {
      backgroundColor: styles.colors.gray[900],
      color: '#FFFFFF',
    },
    secondary: {
      backgroundColor: styles.colors.gray[900],
      color: styles.colors.orange[500],
    },
    tertiary: {
      backgroundColor: '#FFFFFF',
      color: styles.colors.gray[700],
    },
    quaternary: {
      backgroundColor: styles.colors.gray[800],
      color: styles.colors.orange[100],
    },
  },
  iconButtonFill: styles.colors.white,
  iconButtonBackgroundHover: styles.colors.gray[600],
  link: styles.colors.orange[500],
  linkHover: styles.colors.orange[600],
  onSaleRed: '#ff2121',
  inputBorder: styles.colors.gray[600],
  inputFontColor: styles.colors.gray[600],
  tagBackground: styles.colors.gray[800],
  tagBackgroundHover: styles.colors.gray[600],
  cardBackground: styles.colors.gray[800],
  cardBackgroundHover: styles.colors.gray[700],
  cardText: styles.colors.white,
  comparisonChart: {
    alternateRowShade: styles.colors.gray[200],
    cellBorder: styles.colors.gray[200],
    highlightColumn: {
      primary: {
        backgroundColor: styles.colors.orange[500],
        color: styles.colors.white,
      },
      secondary: {
        backgroundColor: styles.colors.gray[500],
        color: styles.colors.white,
      },
      tertiary: {
        backgroundColor: styles.colors.gray[800],
        color: '#FFFFFF',
      },
    },
    mobileSubhead: {
      backgroundColor: styles.colors.orange[100],
    },
  },
  contentCard: {
    primary: {
      backgroundColor: styles.colors.gray[800],
      color: '#FFFFFF',
      border: styles.colors.gray[600],
    },
    secondary: {
      backgroundColor: styles.colors.gray[500],
      color: '#FFFFFF',
      border: styles.colors.gray[600],
    },
    tertiary: {
      backgroundColor: styles.colors.gray[100],
      color: '#000000',
      border: 'none',
    },
    quaternary: {
      backgroundColor: 'transparent',
      color: '#FFFFFF',
      border: styles.colors.gray[600],
    },
  },
  searchBorder: styles.colors.gray[200],
  searchInput: styles.colors.gray[500],
  navSearchAllResultsColor: styles.colors.gray[500],
  navSearchHitsContainerText: styles.colors.gray[800],
  searchFormHitBorderColor: styles.colors.gray[200],
  separatorColor: styles.colors.gray[400],
  typography: {
    brandContrast: styles.colors.gray[700],
  },
};

export default colors;
