const whereToBuyPage = {
  key: 'whereToBuyPage',
  name: 'Where To Buy Page',

  pageTitle: 'Where to Buy SportDOG Hunting Gun Dog Training Collars',
  pageDescription:
    'Find an authorized retailer of SportDOG Brand products. Type in your zip code to get started.',

  heroAlt: 'Where To Buy',
  storeMapperLoadingFromNote: 'Store Locator is loading from {link} ...',
  storeMapperLink: 'Storemapper store locator app',

  warrantyProtectionNote: `To protect your warranty and return policies, ensure you are
                            purchasing from an Authorized Retailer.`,
  authRetailersIconAlt: 'Authorized Retailers',
};

exports.whereToBuyPage = whereToBuyPage;
