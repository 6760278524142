import { useStaticQuery, graphql } from 'gatsby';

const useSiteSettings = locale => {
  const { allSanitySiteSettings } = useStaticQuery(
    graphql`
      query SanitySiteSettingsHookSportDogQuery {
        allSanitySiteSettings(
          filter: {
            _type: { eq: "siteSettings" }
            siteIdentifier: { eq: "sportdog" }
          }
        ) {
          nodes {
            language
            phone
            contactPage: localizedContactPageValue {
              title
              slug {
                current
              }
            }
            notificationBanner {
              text
              link {
                href
                linkType
              }
              blockSvg {
                linkType
                svgUploaded {
                  alt
                  asset {
                    url
                  }
                }
              }
            }
            shipping
            mainMenu: _rawLocalizedMainMenu(resolveReferences: { maxDepth: 10 })
            socialMedia {
              facebookBool
              facebookUrl
              instagramBool
              instagramUrl
              youtubeBool
              youtubeUrl
              twitterBool
              twitterUrl
              pinterestBool
              pinterestUrl
            }
            ordersPageHeadline
            ordersPageSubHeadline
            subscriptionsPageHeadline
            subscriptionsPageSubHeadline
            videosPageHeadline
            videosPageSubHeadline
            addressesPageHeadline
            addressesPageSubHeadline
            settingsPageHeadline
            settingsPageSubHeadline
          }
        }
      }
    `
  );

  const sanitySiteSettings = locale
    ? allSanitySiteSettings.nodes.find(s => s.language === locale)
    : allSanitySiteSettings.nodes[0];

  const defaultSettings = {
    contactPage: '/contact-us/',
    phone: '800.275.6797',
    ordersPageHeadline: 'Your Orders',
    ordersPageSubHeadline: 'View your order history and manage your account.',
    subscriptionsPageHeadline: 'Subscriptions',
    subscriptionsPageSubHeadline:
      'Manage your subscriptions, update payment methods, and view subscription details here.',
    videosPageHeadline: 'Training Videos',
    videosPageSubHeadline:
      'Exclusive training videos to help you train your pet with Invisible Fence technology.',
    addressesPageHeadline: 'Your Addresses',
    addressesPageSubHeadline: 'Manage your delivery addresses here.',
    settingsPageHeadline: 'Your Account',
    settingsPageSubHeadline: 'Manage your account details and password here.',
  };

  const settings = { ...defaultSettings, ...sanitySiteSettings };

  // Ensure contactPage slug starts with '/'
  if (sanitySiteSettings?.contactPage?.slug?.current) {
    settings.contactPage =
      sanitySiteSettings.contactPage.slug.current.startsWith('/')
        ? sanitySiteSettings.contactPage.slug.current
        : `/${sanitySiteSettings.contactPage.slug.current}`;
  }

  return settings;
};

export default useSiteSettings;
