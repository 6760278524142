const logoutPage = {
  key: 'logoutPage',
  name: 'Logout Page',

  pageTitle: 'Logout',
  pageDescription:
    'Hunting dog gear designed by sportsmen. Sporting dog training, tracking, and hunting information to get the most out of your next hunt.',

  goodByeHeader: 'See You Again Soon!',
};

exports.logoutPage = logoutPage;
