const internationalPage = {
  key: 'internationalPage',
  name: 'International Page',

  pageTitle: 'SportDOG® International',
  pageDescription:
    'Hunting dog gear designed by sportsmen. Sporting dog training, tracking, and hunting information to get the most out of your next hunt.',

  selectLocationHeader: 'SELECT A LOCATION',
  sportDogLogoAlt: 'SportDOG Logo',
};

exports.internationalPage = internationalPage;
