const ordersPage = {
  key: 'ordersPage',
  name: 'Orders Page',

  headTagTitle: 'Orders | SportDOG',
  headTagDescription:
    'Hunting dog gear designed by sportsmen. Sporting dog training, tracking, and hunting information to get the most out of your next hunt.',
};

module.exports = { ordersPage };
