const { GLOBAL_LOCALES } = require('@rsc/gatsby-localization-helpers');

const localizedShopifySettings = {
  [GLOBAL_LOCALES.French.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_FR,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_FR,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_FR,
  },
  [GLOBAL_LOCALES.English.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL,
  },
  [GLOBAL_LOCALES.EnglishUK.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_GB,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_GB,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_GB,
  },
  [GLOBAL_LOCALES.Australia.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_AU,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_AU,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_AU,
  },
};

module.exports = {
  localizedShopifySettings,
};
