const donationRequestsCommon = {
  key: 'donationRequestsCommon',
  name: 'Donation Requests Common',

  pageTitle: 'Product Donation Requests',
  pageDescription:
    'We welcome your application for product donations, below. Eligibility Requirements.',
  header: 'Product Donation Requests',

  helpLinksHeader: 'NEED IMMEDIATE HELP?',
  supportAndManualsLink: 'SUPPORT & MANUALS',
  huntingAndTrainingTipsLink: 'HUNTING & TRAINING TIPS',
  registerProductLink: 'REGISTER YOUR SPORTDOG BRAND® PRODUCT',
  returnsAndWarrantyLink: 'RETURNS & WARRANTY',

  heroAlt: 'Donation Requests',

  description:
    'Each year, we set aside a percentage of corporate profits to use for making charitable donations to qualifying organizations. We welcome your application for product donations, below.',
  eligibilityRequirementsHeader: 'Eligibility Requirements',
  firstRequirementDescription: `Product donations are reserved for organizations that promote hunting and conservation,
   shooting sports, dog trialing and training, K9 and military, wildlife habitat improvement, or youth education.`,
  secondRequirementDescription: `Donation requests must be received 90 days in advance of the event. Donations will be reviewed on a quarterly basis.
   Because of the number of requests we receive, we will only contact you if your request has been approved.`,

  formHeader: 'Project Contact Information',
  formOrganizationNameLabel: 'Organization Name',
  formWebsiteLabel: 'Website',
  formContactPersonLabel: 'Contact Person',
  formEmailLabel: 'Email',
  formPhoneNumberLabel: 'Phone Number',
  formShippingAddressLabel: 'Shipping Address (No P.O. Boxes)',
  formCityLabel: 'City',
  formStateLabel: 'State',
  formZipCodeLabel: 'ZIP Code',
  formPurposeOfRequestLabel: 'Purpose of Request',
  formDonateDescriptionLabel: 'What are you requesting us to donate?',

  dropdownPlaceholder: 'Please Choose One',
  purposeGiveawayPrize: 'Giveaway / Prize',
  purposeAuctionRaffle: 'Auction / Raffle',
  purposeOther: 'Other',

  requestPrivacyPolicyNote: `By sending this form, you agree to our use of the personal data
            entered above to communicate with you in regard to your request. For
            more information about our data use policies, see our
            {privacyPolicyLink}`,
  sendApplicationButton: 'Send Application',

  uploadAdditionalInfoHeader: 'Upload Additional Information',
  uploadDescription: `Please use this section to upload any additional information about your
        organization or event, including flyers, or 501(c)(3) documentation.`,
  uploadListHeader: 'Please include:',
  upload1ItemDescription: 'Brief history of your organization',
  upload2ItemDescription: 'Your organization’s geographical area of service',
  upload3ItemDescription: 'Summary of how product(s) will be used',
  upload4ItemDescription: 'Explanation of how SportDOG Brand® will be promoted',
  upload5ItemDescription: `Summary of the program/project, highlighting who will benefit from our
          donation`,
  upload6ItemDescription: 'Date of event',
  upload7ItemDescription: 'Approximate number of people attending your event',
  uploadDocumentsHeader: 'Upload Documents',
  uploadFileSizeInfo: 'Each file must be no larger than 2MB.',
  uploadFileExtensionInfo: 'Allowed filetypes: jpg, png, gif, pdf, docx, doc',

  fileCannotBeUploadedMessage: 'This file will not be uploaded.',
  noFileChosenMessage: 'No file chosen.',
  chooseFileButton: 'Choose File',
  privacyPolicyInlineLink: 'privacy policy',
};

const donationRequestsSuccessPage = {
  key: 'donationRequestsSuccessPage',
  name: 'Donation Requests Success Page',

  pageTitle: 'Success',
  header: 'THANK YOU',
  body: `Thank you for contacting SportDOG. Due to technical issues you may
     experience a delay in the response to your submission. Please bear
      with us as we work to get back with you as quickly as possible.`,
};

const donationRequestsFailPage = {
  key: 'donationRequestsFailPage',
  name: 'Donation Requests Fail Page',

  pageTitle: 'Fail',
  header: 'Error',
  body: `Unfortunately, we were unable to process this request. Please try
          again later or {contactUsLink} 
          via a different method.`,
  contactUsInlineLink: 'contact us',
};

module.exports = {
  donationRequestsCommon,
  donationRequestsSuccessPage,
  donationRequestsFailPage,
};
