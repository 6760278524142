const searchPage = {
  key: 'searchPage',
  name: 'Search Page',

  pageTitle: 'Search',
  productsHeader: 'Products ({number})',
  articlesHeader: 'Articles ({number})',
};

exports.searchPage = searchPage;
