const collectionsPage = {
  key: 'collectionsPage',
  name: 'Collections Page',

  sidebarHeader: 'Go To',
  collectionTitleReplaceValue: 'Parts & Accessories -',
  learnMoreLink: 'Learn More',
  filterBoxGoToOption: 'Go To',
  sortByLabel: 'Sort by:',
  featured: 'Featured',
  newest: 'Newest',
  atoz: 'A to Z',
  ztoa: 'Z to A',
  priceAsc: 'Price: Asc',
  priceDesc: 'Price: Desc',
  relatedArticlesHeader: 'RELATED ARTICLES',
};

module.exports = {
  collectionsPage,
};
