const articleListPage = {
  key: 'articleListPage',
  name: 'Article List Page',

  metaTitle: 'Hunting & Dog Training Articles and Videos{pageTitleModifier}',
  metaDescription:
    'Check out articles and videos about dog training, hunting dog health, hunting tips, and other topics for you and your sporting dog.',
  heroHeadline1: 'Training Tips',
  heroHeadline2: "Here's how we make the most of each hunt",
  heroAltText: 'Training Tips',
  categoriesHeader: 'Categories:',
  authorSubtitlePlural: 'articles',
  authorSubtitleSingular: 'article',
  articleSubtitle: 'by {authorTitle}',
  articleSubtitleDefault: 'by The SportDOG Staff',
  authorsHeader: 'AUTHORS',
  metaTitleAuthors: 'Authors',
};

module.exports = {
  articleListPage,
};
