const pressRoomListPage = {
  key: 'pressRoomListPage',
  name: 'Press Room List Page',

  metaTitle: 'Press Room - {currentYear}',
  defaultMetaTitle: 'Press Room',
  metaDescription:
    'The SportDOG Brand Press Room. Read the latest press releases on SportDOG products, community outreach and Conservation Fund efforts, and hunting events. Browse the press release archive through 2003.',
  heroHeadline1: 'Press Room',
  heroAltText: 'Press Room',
  pressReleasesHeader: 'Press Releases',
  viewReleaseLink: 'View Release',
};

module.exports = {
  pressRoomListPage,
};
