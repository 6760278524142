const pressRoomDetailPage = {
  key: 'pressRoomDetailPage',
  name: 'Press Room Detail Page',

  heroHeadline1: 'Press Room',
  heroAltText: 'Press Room',
  aboutSportdogBrand: 'About SportDOG Brand',
  footerParagraph: `{aboutSportdogBrand} - SportDOG® Brand is owned
    by Radio Systems Corporation®, a manufacturer of electronic
    dog-training gear and accessories, based in Knoxville, Tennessee.
    SportDOG product lines include training e-collars, GPS tracking
    and training systems, bark-control collars, containment systems,
    launcher electronics and other training aids.`,
};

module.exports = {
  pressRoomDetailPage,
};
