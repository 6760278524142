const emailUpdatesPage = {
  key: 'emailUpdatesPage',
  name: 'Email Updates Page',

  pageTitle: 'Sign up for Newsletter',
  pageDescription:
    'Get Email Updates. Sign up for exclusive training tips, product information, promotions, and information about conservation efforts.',
  heroAlt: 'Get Email Updates',

  checkboxesSectionHeader: 'I am interested in the following:',
  houndCheckbox: 'Hound',
  waterfowlCheckBox: 'Waterfowl',
  uplandCheckBox: 'Upland',
  obedienceTrainingCheckBox: 'Obedience Training',

  emailFieldLabel: 'Email',
  emailSignUpNote: `Sign up for exclusive training tips, product information, promotions,
          and information about conservation efforts. We won’t overwhelm you
          with emails, and we promise not to send you anything we wouldn’t want
          in our inbox.`,
  submitButton: 'Submit',
  cancelAndReturnLink: 'Cancel and return to previous page',
  submitErrorMessage: 'Form submission failed. Please try again later.',
};

const emailUpdatesSuccessPage = {
  key: 'emailUpdatesSuccessPage',
  name: 'Email Updates Success Page',
  pageTitle: 'Success',

  header: 'Success!',
  body: `Thank you for signing up for our mailing list! Expect your first email
          shortly.`,
  continueShoppingButton: 'Continue Shopping',
};

module.exports = {
  emailUpdatesPage,
  emailUpdatesSuccessPage,
};
