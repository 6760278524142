const validationMessagesSportdogCommon = {
  key: 'validationMessagesSportdogCommon',
  name: 'Validation Messages Sportdog Common',

  fieldRequiredValidationMessage: '{label} is a required field',
  fieldInvalidValueValidationMessage: 'Invalid value for {label}',

  invalidFileExtensionValidationMessage: 'Invalid file extension',
  fileSizeIsLargeValidationMessage: 'File is too large',
};

exports.validationMessagesSportdogCommon = validationMessagesSportdogCommon;
