import * as React from 'react';
import { ReactElement } from 'react';
import {
  InternationalContext,
  getShopifySettings,
} from '@rsc/gatsby-localization-helpers';
import { CartProvider } from '@rsc/contexts/CartContext';
import CustomerContextProvider from '@rsc/contexts/CustomerContext';
import { localizedShopifySettings } from '@util/shopify-settings';
import { SiteSettingsProvider } from '@rsc/contexts/SiteSettingsContext';
import useSiteSettings from '@components/hooks/useSiteSettings';
import { useSportdogStaticContentLocalizer } from '@util/static-translations';
import { defaultLanguage } from './src/locales';

interface PageWrapperProps {
  element: ReactElement;
  props: { pageContext: { locale: string } };
}

const InternationalContextWrapper = ({ children, locale }: any) => {
  const staticContentLocalizer = useSportdogStaticContentLocalizer(locale);

  return (
    <InternationalContext.Provider value={staticContentLocalizer}>
      {children}
    </InternationalContext.Provider>
  );
};

export function PageWrapper({ element, props }: Readonly<PageWrapperProps>) {
  const { locale = defaultLanguage.id } = props.pageContext;

  const shopifySettings = getShopifySettings(localizedShopifySettings, locale);

  return (
    <InternationalContextWrapper locale={locale}>
      <SiteSettingsProvider
        useSiteSettings={useSiteSettings}
        siteIdentifier="sportdog"
      >
        <CustomerContextProvider shopifySettings={shopifySettings}>
          <CartProvider shopifySettings={shopifySettings}>
            {element}
          </CartProvider>
        </CustomerContextProvider>
      </SiteSettingsProvider>
    </InternationalContextWrapper>
  );
}
